.yesplz-text-search {
  color: #000000;
  font-size: 14px;

  &-logo {
    display: none;

    @media (min-width: 768px) {
      display: block;
      position: absolute;
      top: 48px;
      left: 0;
      width: auto;
    }
  }

  &-container {
    @media (min-width: 768px) {
      // padding-left: 264px;
      // padding-bottom: 30px;
      // max-width: 1440px;
    }
  }

  &-backdrop {
    background-color: #fff;

    @media (min-width: 768px) {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &-main {
    min-height: 556px;
    padding: 0 20px 20px;

    @media (min-width: 768px) {
      padding: 0 48px 48px;
    }

    @media (min-width: 1376px) {
      padding: 0 68px 68px;
    }
  }
  &-header {
    margin: 0 -20px;

    @media (min-width: 768px) {
      margin: 0;
      justify-content: center;
      padding-top: 32px;
      flex-direction: column;
      align-items: center;
    }
  }
  &-bar-container {
    background-color: #222222;
    height: 48px;
    padding: 0;
    margin-bottom: 5px;

    @media (min-width: 768px) {
      background-color: transparent;
      border: 1px solid #000000;
      margin-bottom: 32px;
    }
  }
  &-close-button {
    box-shadow: none !important;
    width: auto;
    top: 11px;
    right: 0;
    
    @media (min-width: 768px) {
      top: 40px;
      right: 0;
      transform: scale(0.8);
      width: 20px;
      height: 20px;

      &::before {
        width: 15px;
        height: 15px;
        background-image: url('./assets/close-button.svg');
        background-color: transparent;
        transform: none;
      }
      &::after {
        display: none;
      }
    }

    &::before {
      @media (max-width: 768px) {
        content: 'Cancel';
        transform: none;
        background: transparent;
        position: static;
        width: auto;
        height: auto;
        color: #ffffff;
        font-weight: normal;
      }
    }
    &::after {
      @media (max-width: 768px) {
        display: none;
      }
    }

    &:hover {
      background-color: transparent;
      border: 0;
    }
  }

  &-form {
    margin-right: 64px;
    position: relative;

    @media (min-width: 768px) {
      margin-right: 0;
      width: 550px;
    }

    > input {
      background-color: transparent;
      color: #ffffff;
      height: 48px;
      border-radius: 0;
      padding-left: 46px;
      border: 0;

      @media (min-width: 768px) {
        color: #000000;
        padding-right: 46px;
        padding-left: 15px;
      }

      &:focus {
        box-shadow: none !important;
        background-color: transparent;
        color: #ffffff;
        border: 0;

        @media (min-width: 768px) {
          color: #000000;
        }
      }

      &::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }
    }

    > button {
      box-shadow: none !important;

      &.submit {
        background: url('./assets/search-icon.svg') center center no-repeat;
        position: absolute;
        top: 50%;
        left: auto;
        left: 10px;
        transform: translate3d(0, -50%, 0);

        @media (min-width: 768px) {
          background-image: url('./assets/search-icon-black.svg');
          left: auto;
          right: 7px;
        }
      }

      &.clear {
        display: none;
        background-color: transparent;
        width: 16px;
        height: 16px;
        top: 50%;
        right: 20px;
        transform: translate3d(0, -50%, 0);
        background-image: url('./assets/darker-close-button.svg');

        @media (min-width: 768px) {
          background-image: url('./assets/light-close-button.svg');
          right: 46px;
        }
        
        &::after, &::before {
          display: none;
        }

        &.is-active {
          display: block;
        }
      }

      &:hover {
        border: 0;
      }
    }
  }

  &-menu-container {
    @media (min-width: 768px) {
      position: relative;
      padding-bottom: 26px;
      width: 100%;
      display: flex;
      // flex-direction: row-reverse;
      margin-bottom: 28px;
      padding-bottom: 0;
      width: 100%;

      &::before {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        background-color: #DDDDDD;
      }
    }
    .yesplz-menu.sale-container {
      margin-left: 0;
      display: block;
      position: absolute;
      top: 77px;
      right: 20px;
      // position: static;

      @media (min-width: 768px) {
        margin-top: 0;
        top: 20px;
        right: 0;
      }

      ul {
        @media (min-width: 768px) {
          padding: 0;
        }
        li.sale {
          padding-right: 0;
          padding-left: 30px;
          font-weight: normal;
          opacity: 1;
          font-size: 12px;
          color: #000000;
          line-height: 20px;
          text-transform: capitalize;

          @media (min-width: 768px) {
            font-size: 14px;
          }

          &::before, &::after {
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 0;
            box-shadow: none;
            top: 2px;
            left: 0;
            border: 0;
          }

          &::before {
            top: 0;
            background-color: #000;
            background: url('~@yesplz/core-web/assets/svg/mobile-tabbed-checkbox.svg') transparent no-repeat center center;
          }
          &::after {
            display: none;
          }

          &.is-active {
            &::before {
              background-image: url('~@yesplz/core-web/assets/svg/mobile-tabbed-checkbox-checked.svg');
            }
          }
        }
      }
    }

    .yesplz-menu {
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: 14px;

      @media (min-width: 768px) {
        width: auto;
        margin-bottom: 0;
        margin-right: 0;
        margin-top: 20px;
      }

      ul {
        padding: 0 20px;
        margin-bottom: 20px;
        width: 100%;

        @media (min-width: 768px) {
          margin-bottom: 0;
          border-bottom: 0;
        }

        li {
          margin-bottom: 0;
          margin-right: 20px;
          font-size: 14px;
          line-height: 21px;
          padding: 12px 0;
          
          @media (min-width: 768px) {
            padding: 0;
            border-bottom: 0;
            opacity: 0.34;
            font-size: 14px;
            font-style: normal;
            line-height: 26px;
          }

          &.is-active {
            opacity: 1;
            font-weight: 500;

            @media (min-width: 768px) {
              opacity: 1;
              font-weight: 400;
            }

            &::after {
              height: 1px;

              @media (min-width: 768px) {
                display: none;
              }
            }
          }
        }
      }
    }

    .yesplz-suggestions {
      @media (min-width: 768px) {
        padding-top: 0;
        top: 81px;
        left: 0;
        width: 20%;
      }

      h5 {
        color: inherit;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 16px;
        font-size: 14px;
        
        @media (min-width: 768px) {
          font-size: 13px;
          font-weight: 400;
          margin-bottom: 8px;
        }
      }
      ul#yesplz-suggested-queries {
        max-width: 100%;
        li {
          font-size: 13px;
          line-height: 21px;
          font-weight: 400;
          font-weight: normal;
          padding: 12px 0;
          margin-bottom: 0;
          font-family: inherit;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (min-width: 768px) {
            font-size: 12px;
            padding: 8px 20px 8px 0;
          }

          &.suggestion-type-separator {
            display: none;
          }
        }
      }
    }
  }

  &-products {
    > h3 {
      display: none;
    }

    ul {
      display: flex !important;
    }

    @media (min-width: 768px) {
      padding-left: 20%;
      padding-top: 6px;

      ul {
        display: grid !important;
      }

      > h3 {
        display: block;
        font-size: 13px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 16px;
      }

      .product-item {
        width: auto;
      }

      #yesplz-text-search-products {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &-footer {
    text-align: right;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      margin-bottom: 0;
      position: absolute;
      bottom: -41px;
      right: 0;
    }

    > button {
      box-shadow: none !important;
      padding-right: 0;
      padding-left: 0;
      font-weight: normal;
      font-family: inherit;
      font-size: 12px;

      @media (min-width: 768px) {
        font-size: 13px;
      }

      &:hover {
        background-color: transparent;
        border: 0;
      }
    }
  }

  &-popular-queries {
    padding-top: 30px;
    
    @media (min-width: 768px) {
      padding-top: 0;
    }

    h4 {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      font-style: normal;
      line-height: 21px;
      margin-bottom: 11px;
    }

    ul {
      li {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        font-weight: normal;
        padding: 12px 0;
        margin-bottom: 0;
      }
    }
  }
  
  &-empty-results {
    display: none;

    padding-top: 58px;
    max-height: 50vh;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
      padding-top: 116px;
    }

    h3 {
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      margin: 0;

      @media (min-width: 768px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  &.no-results, &.empty-results {
    .yesplz-text-search-menu-container, .yesplz-text-search-footer {
      display: none;
    }
  }

  &.empty-results {
    .yesplz-text-search-empty-results {
      display: flex;
    }

    .yesplz-text-search-products {
      display: none;
    }

    .yesplz-text-search-menu-container {
      display: block;

      .yesplz-suggestions, .yesplz-menu.sale-container {
        display: none;
      }
    }

    @media (min-width: 768px) {
      .yesplz-text-search-header {
        margin-left: 0;
      }
      .yesplz-text-search-container {
        padding-left: 0;
      }
    }
  }
}
